<template>
  <v-data-table
    :headers="headers"
    :items="packages"
    :search="search"
    :server-items-length="total"
    :options.sync="serverOptions"
    :sort-by.sync="sortBy"
    :sort-desc.sync="sortDesc"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <!-- <v-toolbar-title> -->
        <v-text-field
          v-model="search"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
        <!-- </v-toolbar-title> -->
        <!-- <v-divider class="mx-4" inset vertical></v-divider> -->
        <v-spacer></v-spacer>

        <v-dialog
          v-model="dialogDelete"
          max-width="600px"
        >
          <v-card>
            <v-card-title class="text-h5 mb-5">
              Are you sure you want to delete this item?
            </v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="error"
                outlined
                @click="closeDelete"
              >
                Cancel
              </v-btn>
              <v-btn
                color="primary"
                @click="deleteItemConfirm"
              >
                OK
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.index="{ item }">
      {{ packages.indexOf(item) + 1 }}
    </template>
    <template v-slot:item.mem_date="{ item }">
      <span v-if="item.emp_date && item.emp_time">
        {{item.emp_date}} {{item.emp_time}}
      </span>
      <span v-if="item.mem_date && item.mem_time">
        {{item.mem_date}} {{item.mem_time}}
      </span>
    </template>
    <template v-slot:item.ref="{ item }">
     {{( (item.order && item.order.order_inv) || (item.claim && item.claim.claim_no) || (item.member_package && item.member_package.mem_package_no)) || item.note || '-'}}
    </template>
    <template v-slot:item.memdate2="{ item }">

    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon
        small
        class="mr-2"
        @click="editItem(item)"
      >
        {{ icons.mdiPencil }}
      </v-icon>
      <v-icon
        small
        @click="deleteItem(item)"
      >
        {{ icons.mdiDelete }}
      </v-icon>
    </template>
    <template v-slot:no-data>
      <!-- <v-btn
        color="primary"
        @click="initialize"
      >
        Reset
      </v-btn> -->
    </template>
  </v-data-table>
</template>

<script>
import { mdiPencil, mdiDelete } from '@mdi/js'
// import config from '../../../config'
import instance from '@/axios.service'
import instance_movement from '@/services/memberMovement'

export default {
  props : ['member_id'],
  setup() {
    return {
      icons: {
        mdiPencil,
        mdiDelete,
      },
    }
  },
  data: () => ({
    serverOptions : {
      page: 1,
      itemsPerPage: 10,

    },
    total : 0,
    search: '',
    sortBy: '',
    sortDesc: '',
    packages: [],
    dialog: false,
    dialogDelete: false,
    headers: [
      {
        text: 'ลำดับ',
        value: 'index', sortable : false
      },
      { text: 'วัน-เวลา', value: 'mem_date', sortable : false },
      { text: 'เลขอ้างอิง', value: 'ref' , sortable : false},
      { text: 'ประเภทเครดิต', value: 'product_type.product_type_name', sortable : false },

      { text: 'เครดิต', value: 'credit_product_amt' , sortable : false},
      // <td>
      //                                                   <span
      //                                                     v-if="creditProduct.credit_product_amt && creditProduct.credit_product_amt > 0"
      //                                                     className="credit-push">
      //                                                   +@{{creditProduct.credit_product_amt || 0}}
      //                                                   </span>
      // </td>
      { text: 'ตัดเครดิต', value: 'credit_product_amt' , sortable : false},

      // <span
      //   v-if="creditProduct.credit_product_amt && creditProduct.credit_product_amt <  0"
      //   className="credit-remove">
      //                                                    @{{creditProduct.credit_product_amt || 0}}
      //                                                   </span>
      { text: 'คงเหลือ', value: 'remain', sortable : false },
      { text: 'พนักงาน', value: 'employee.employee_name', sortable : false },
    ],
    desserts: [],
    editedIndex: -1,
    editedItem: {
      numberCustomer: '',
      status: '',
      numberTax: null,
      nameCustomer: null,
      credit: null,
      dateBill: '',
      dateGetMoney: '',
    },
    defaultItem: {
      numberCustomer: '',
      status: '',
      numberTax: null,
      nameCustomer: null,
      credit: null,
      dateBill: '',
      dateGetMoney: '',
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'เพิ่ม กลุ่มพนักงาน' : 'แก้ไข กลุ่มพนักงาน'
    },
  },

  watch: {
    serverOptions : {
      handler(){
        this.getCreditProductReport()
      },
    },
    search : {
      handler(){
        this.getCreditProductReport()
      },
    },
    // dialog(val) {
    //   val || this.close()
    // },
    // dialogDelete(val) {
    //   val || this.closeDelete()
    // },
  },

  created() {
    // this.initialize()
  },
  mounted() {
    this.getCreditProductReport()
    // this.getUser()
  },

  methods: {
    editItem(item) {
      this.editedIndex = this.desserts.indexOf(item)
      this.editedItem = { ...item }
      this.dialog = true
    },

    getCreditProductReport(){
      var data = {
        member_id : this.member_id,
        search: this.search,
        page : this.serverOptions.page,
        size : this.serverOptions.itemsPerPage,
        sortBy : this.sortBy ?? "",
        sortDesc : this.sortDesc ?? ''
      }

      this.$store.state.isLoading = true;
      instance_movement.getPackage(data).then(res => {
        this.packages = res.data.data.data
        this.total = res.data.data.total
        this.serverOptions.itemsPerPage = this.serverOptions.itemsPerPage == -1 ? -1 : res.data.data.per_page
        this.serverOptions.page = res.data.data.current_page

        this.$store.state.isLoading = false;
      }).catch(err => {
        this.$store.state.isLoading = false;

      })
    },
    deleteItem(item) {
      this.editedIndex = this.desserts.indexOf(item)
      this.editedItem = { ...item }
      this.dialogDelete = true
    },

    deleteItemConfirm() {
      this.desserts.splice(this.editedIndex, 1)
      this.closeDelete()
    },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem }
        this.editedIndex = -1
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem }
        this.editedIndex = -1
      })
    },

    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.desserts[this.editedIndex], this.editedItem)
      } else {
        this.desserts.push(this.editedItem)
      }
      this.close()
    },
    getUser() {
      // eslint-disable-next-line no-useless-concat
      const baseURL = `${process.env.VUE_APP_BASEURL}` + '/login'
      instance
        .get(`${baseURL}user?search=`)
        .then(res => {
          if (res.status === 200) {
            this.users = res.data.data
          }

          // Unauthorized Logout
          // eslint-disable-next-line no-empty
          if (res.status === 200 && res.data.error === 'Unauthorized') {
            localStorage.removeItem('token_authentication')
          }
        })
        .catch(error => {
          console.log(error)

        })
    },
  },
}
</script>

<style scoped>
hr {
  background-color: #e5e5e5;
  height: 1px;
  border: none;
}
</style>
