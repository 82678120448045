<template>
  <v-data-table
    :headers="headers"
    :items="orders"
    :search="search"
    :server-items-length="total"
    :options.sync="serverOptions"
    :sort-by.sync="sortBy"
    :sort-desc.sync="sortDesc"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <!-- <v-toolbar-title> -->
        <!-- </v-toolbar-title> -->
        <!-- <v-divider class="mx-4" inset vertical></v-divider> -->
        <!--        <v-spacer></v-spacer>-->
        <v-row>
          <v-col cols="5">
            <v-text-field
              v-model="search"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="5">
            <DatePicker
              v-model="date"
              range
              multi-calendars
              value-type="format"
              format="YYYY-MM-DD"
              placeholder="เลือกเฉพาะวันที่"
              style="width: 100%"
              @change="getOrderReport()"
            ></DatePicker>
          </v-col>
          <v-col cols="2">
            <v-btn
              color="primary"
              dark
              class="mb-2"
              @click="print"
            >
              พิมพ์รายการสั่งซื้อ
            </v-btn>
          </v-col>
        </v-row>
        <v-dialog
          v-model="dialogDelete"
          max-width="600px"
        >
          <v-card>
            <v-card-title class="text-h5 mb-5">
              Are you sure you want to delete this item?
            </v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="error"
                outlined
                @click="closeDelete"
              >
                Cancel
              </v-btn>
              <v-btn
                color="primary"
                @click="deleteItemConfirm"
              >
                OK
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.index="{ index }">
      {{ ((serverOptions.page-1)*serverOptions.itemsPerPage) + index +1 }}
    </template>
    <template v-slot:item.order_status="{ item }">
      <span v-if="item.order_status === 'PT'">รอชำระเงิน</span>
      <span v-if="item.order_status === 'PC'">รอตรวจสอบ</span>

      <span v-if="item.order_status === 'PTP'">เตรียมจัดส่ง</span>
      <span v-if="item.order_status === 'PP'">กำลังผลิต</span>

      <span v-if="item.order_status === 'F'">เสร็จสิ้น</span>
      <span v-if="item.order_status === 'C'">ยกเลิก</span>
    </template>
    <template v-slot:item.cost="{ item }">
      {{ item.order_expense - item.transport_expense_price }}
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon
        small
        class="mr-2"
        @click="editItem(item)"
      >
        {{ icons.mdiPencil }}
      </v-icon>
      <v-icon
        small
        @click="deleteItem(item)"
      >
        {{ icons.mdiDelete }}
      </v-icon>
    </template>
    <template v-slot:no-data>
      <!-- <v-btn
        color="primary"
        @click="initialize"
      >
        Reset
      </v-btn> -->
    </template>
  </v-data-table>
</template>

<script>
import { mdiPencil, mdiDelete } from '@mdi/js'

// import config from '@/config'
import DatePicker from 'vue2-datepicker'
import instance from '@/axios.service'
import instance_member_movement from '@/services/memberMovement'

export default {
  components: { DatePicker },
  props: ['member_id'],
  setup() {
    return {
      icons: {
        mdiPencil,
        mdiDelete,
      },
    }
  },
  data: () => ({
    orders: [],
    dialog: false,
    dialogDelete: false,
    serverOptions: {
      page: 1,
      itemsPerPage: 10,

    },
    date: '',
    total: 0,
    search: '',
    sortBy: '',
    sortDesc: '',
    headers: [
      {
        text: '#',
        value: 'index',
        sortable: false,
      },
      { text: 'เลขใบสั่งซื้อ', value: 'order_inv', sortable: true },
      { text: 'วันที่สั่งซื้อ', value: 'order_date_time', sortable: true },
      { text: 'จำนวนรายการ', value: 'count_order', sortable: false },
      { text: 'จำนวนชิ้น', value: 'sum_product_amount', sortable: false },
      { text: 'ค่าสินค้า', value: 'cost', sortable: false },
      { text: 'ค่าส่ง', value: 'transport_expense_price', sortable: false },
      { text: 'ส่วนลด', value: 'order_price_discount', sortable: false },
      { text: 'รวมสุทธิ', value: 'order_expense', sortable: false },
      { text: 'สถานะ', value: 'order_status', sortable: false },

      // <td>
      //                                                   <span style="color:#e8a403;"
      //                                                         v-if="booking_order.order_status === 'PT'">รอดำเนินการโอน</span>
      //   <span style="color:#e8a403;"
      //         v-if="booking_order.order_status === 'PC'">รอการตรวจสอบ</span>
      //   <span style="color:#e8a403;"
      //         v-if="booking_order.order_status === 'PP'">อยู่ระหว่างดำเนินการผลิต</span>
      //   <span style="color:#e8a403;"
      //         v-if="booking_order.order_status === 'PTP'">เตรียมจัดส่ง</span>
      //   <span style="color:green;"
      //         v-if="booking_order.order_status === 'F'">ส่งสำเสร็จ</span>
      //   <span style="color:red;"
      //         v-if="booking_order.order_status === 'C'">ยกเลิก</span>
      //
      // </td>
    ],
    editedIndex: -1,
    editedItem: {
      numberCustomer: '',
      status: '',
      numberTax: null,
      nameCustomer: null,
      credit: null,
      dateBill: '',
      dateGetMoney: '',
    },
    defaultItem: {
      numberCustomer: '',
      status: '',
      numberTax: null,
      nameCustomer: null,
      credit: null,
      dateBill: '',
      dateGetMoney: '',
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'เพิ่ม กลุ่มพนักงาน' : 'แก้ไข กลุ่มพนักงาน'
    },
  },

  watch: {
    serverOptions: {
      handler() {
        this.getOrderReport()
      },
    },
    search: {
      handler() {
        this.getOrderReport()
      },
    },

    // dialog(val) {
    //   val || this.close()
    // },
    // dialogDelete(val) {
    //   val || this.closeDelete()
    // },
  },

  created() {
    // this.initialize()
  },
  mounted() {
    // this.getUser()
    this.getOrderReport()
  },

  methods: {
    print() {
      let url = `/print_member_order/${this.member_id}`
      if (this.date) {
        url += `?date=${this.date[0]},${this.date[1]}`
      }
      window.open(url, '_blank', 'noreferrer')
    },
    editItem(item) {
      this.editedIndex = this.desserts.indexOf(item)
      this.editedItem = { ...item }
      this.dialog = true
    },

    deleteItem(item) {
      this.editedIndex = this.desserts.indexOf(item)
      this.editedItem = { ...item }
      this.dialogDelete = true
    },

    deleteItemConfirm() {
      this.desserts.splice(this.editedIndex, 1)
      this.closeDelete()
    },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem }
        this.editedIndex = -1
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem }
        this.editedIndex = -1
      })
    },

    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.desserts[this.editedIndex], this.editedItem)
      } else {
        this.desserts.push(this.editedItem)
      }
      this.close()
    },
    getOrderReport() {
      const data = {
        member_id: this.member_id,
        search: this.search,
        page: this.serverOptions.page,
        size: this.serverOptions.itemsPerPage,
        sortBy: this.sortBy ?? '',
        sortDesc: this.sortDesc ?? '',
        date: this.date,
      }
      this.$store.state.isLoading = true
      instance_member_movement.getOrder(data).then(res => {
        this.orders = res.data.data.data
        this.total = res.data.data.total
        this.serverOptions.itemsPerPage = this.serverOptions.itemsPerPage == -1 ? -1 : res.data.data.per_page
        this.serverOptions.page = res.data.data.current_page
        this.$store.state.isLoading = false
      }).catch(err => {
        this.$store.state.isLoading = false
      })
    },
  },
}
</script>

<style scoped>
hr {
  background-color: #e5e5e5;
  height: 1px;
  border: none;
}
</style>
