/* eslint-disable no-undef */
/* eslint-disable import/no-cycle */
import api from '../axios.service'

export default {

  getOrder(data) {
    return api.get(`member/member_report/order?search=${data.search}&member_id=${data.member_id}&type=all&page=${data.page}&size=${data.size}&sortBy=${data.sortBy}&sortDesc=${data.sortDesc}&date=${data.date}`)
  },

  getPackage(data) {
    return api.get(`member/member_report/package?search=${data.search}&user_id=${data.member_id}&type=all&page=${data.page}&size=${data.size}&sortBy=${data.sortBy}&sortDesc=${data.sortDesc}`)
  },

  getCreditCustomer(data) {
    return api.get(`member/member_report/credit_customer?search=${data.search}&user_id=${data.member_id}&type=all&page=${data.page}&size=${data.size}&sortBy=${data.sortBy}&sortDesc=${data.sortDesc}`)
  },

  getCustomerPackage(data) {
    return api.get(`member/member_report/member_package?search=${data.search}&user_id=${data.member_id}&type=all&page=${data.page}&size=${data.size}&sortBy=${data.sortBy}&sortDesc=${data.sortDesc}`)
  },

}
