<template>
  <v-card id="account-setting-card">
    <!-- tabs -->
    <v-tabs v-model="tab" show-arrows>
      <v-tab v-for="tab in tabs" :key="tab.icon">
        <v-icon size="20" class="me-3">
          {{ tab.icon }}
        </v-icon>
        <span>{{ tab.title }}</span>
      </v-tab>
    </v-tabs>

    <!-- tabs item -->
<!--    <v-breadcrumbs :items="items" class="pb-0">-->
<!--      <template v-slot:divider>-->
<!--        <v-icon>mdi-chevron-right</v-icon>-->
<!--      </template>-->
<!--    </v-breadcrumbs>-->
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <OrderReport :member_id="member_id"/>
      </v-tab-item>

      <v-tab-item>
        <CreditProductReport :member_id="member_id" />
      </v-tab-item>

      <v-tab-item>
        <CashReport :member_id="member_id"/>
      </v-tab-item>

      <v-tab-item>
        <AddReport :member_id="member_id"/>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import { mdiCartOutline, mdiCartPlus, mdiMore } from '@mdi/js'
import { ref } from '@vue/composition-api'

// demos
import OrderReport from './OrderReport.vue'
import CreditProductReport from './CreditProductReport.vue'
import CashReport from './CashReport.vue'
import AddReport from './AddReport.vue'
import {mdiAbacus} from "@mdi/js/commonjs/mdi";


export default {
  components: {
    OrderReport,
    CreditProductReport,
    CashReport,
    AddReport,
  },
  setup() {
    const tab = ref('')

    // tabs
    const tabs = [
      { title: 'รายการสั่งซื้อ', icon: mdiCartOutline },
      { title: 'รายการเครดิตสินค้า', icon: mdiCartPlus },
      { title: 'รายการยอดเงินในระบบ', icon: mdiMore },
      { title: 'รายงานการเติม package', icon: mdiAbacus },
    ]

    // account settings data
    const accountSettingData = {
      account: {
        avatarImg: require('@/assets/images/avatars/1.png'),
        username: 'whatyouwantshop',
        name: 'whatyouwantshop',
        email: 'whatyouwantshop@example.com',
        role: 'Admin',
        status: 'Active',
        company: 'Google.inc',
      },
      information: {
        bio: '',
        birthday: 'February 22, 1995',
        phone: '09461075555',
        website: 'https://whatyouwantshop.com/',
        country: 'USA',
        languages: ['English', 'Spanish'],
        gender: 'male',
      },
    }

    return {
      tab,
      tabs,
      accountSettingData,
      icons: {
        mdiCartOutline,
        mdiCartPlus,
        mdiMore,
      },
    }
  },
  data: () => ({
    member_id : '',
    // items: [
    //   {
    //     text: 'สินค้า',
    //     disabled: false,
    //     href: 'product',
    //   },
    //   {
    //     text: 'สต๊อก',
    //     disabled: true,
    //     href: 'stock',
    //   },
    // ],
  }),
  methods : {
    get_order(){

    }
  },
  mounted() {
    this.member_id = this.$route.params.id;
      this.get_order();
  }
}
</script>
