var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.credit_customers,"search":_vm.search,"server-items-length":_vm.total,"options":_vm.serverOptions,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc},on:{"update:options":function($event){_vm.serverOptions=$event},"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-text-field',{attrs:{"label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"600px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 mb-5"},[_vm._v(" Are you sure you want to delete this item? ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"error","outlined":""},on:{"click":_vm.closeDelete}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.deleteItemConfirm}},[_vm._v(" OK ")]),_c('v-spacer')],1)],1)],1)],1)]},proxy:true},{key:"item.index",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.credit_customers.indexOf(item) + 1)+" ")]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [(item.type === 'Add')?_c('span',{staticStyle:{"color":"green"}},[_vm._v("เติม")]):_vm._e(),(item.type === 'Delete')?_c('span',{staticStyle:{"color":"red"}},[_vm._v("ถอน")]):_vm._e()]}},{key:"item.type2",fn:function(ref){
var item = ref.item;
return undefined}},{key:"item.ref",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(( (item.order && item.order.order_inv) || (item.claim && item.claim.claim_no) || (item.mem_package_credit && item.mem_package_credit.mem_package_no)|| (item.package_credits_on && item.package_credits_on)) || item.note || '-')+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiPencil)+" ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiDelete)+" ")])]}},{key:"no-data",fn:function(){return undefined},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }